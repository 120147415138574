<template>
  <div>
    <el-row :gutter="20">
      <el-col :sm="{ span: 12 }">
        <el-card class="box-card">
          <div id="sg-admin-chart-2" class="sg-chart"></div>
        </el-card>
      </el-col>
      <el-col :sm="{ span: 12 }">
        <el-card class="box-card">
          <div id="sg-admin-chart-3" class="sg-chart"></div>
        </el-card>
      </el-col>
    </el-row>
    <el-row class="sg-margin-top">
      <el-col :sm="{ span: 24 }">
        <el-card class="box-card">
          <div id="sg-admin-chart-1" class="sg-chart"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { option1, option2, option3 } from "@/services/chart_test";

export default {
  name: "Home",
  props: {},
  data: () => {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    var chart1 = this.$echarts.init(
      document.getElementById("sg-admin-chart-1")
    );
    chart1.setOption(option1)
    // chart1.resize({
    //   width: '100%',
    //   height: 300,
    // });

    var chart2 = this.$echarts.init(
      document.getElementById("sg-admin-chart-2")
    );
    chart2.setOption(option2)

    var chart3 = this.$echarts.init(
      document.getElementById("sg-admin-chart-3")
    );
    chart3.setOption(option3)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-chart {
  height: 300px;
  width: 100%;
}
</style>
